<template>
    <div>
        <div class="lv_flex ls_aic ls_flex_wrap" style="margin-bottom: 15px">
            <div>用户创建时间：</div>
            <el-date-picker class="mr20 mb10" v-model="time" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>

            <div>用户更新时间：</div>
            <el-date-picker class="mr20 mb10" v-model="time2" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            <el-input class="w300 mr20 mb10" v-model="params.nickName" placeholder="用户昵称（模糊）" />
            <el-input class="w300 mr20 mb10" v-model="params.phoneNumber" placeholder="手机号码（精确）" />
            <el-button type="primary" @click="params.pageNum = 1; getList();">
                搜索
            </el-button>
        </div>
        <div style="margin: 20px 0">
            <h1 style="font-size: 40px">{{ total }}</h1>
            <div style="margin-top: 10px">总计用户数</div>
        </div>
        <el-table :data="list" style="width: 100%">
            <el-table-column prop="nickName" label="用户昵称"></el-table-column>
            <el-table-column prop="phoneNumber" label="手机号码"></el-table-column>
            <el-table-column prop="province" label="注册省份"></el-table-column>
            <el-table-column prop="city" label="注册城市"></el-table-column>
            <el-table-column prop="insertTime" label="创建时间"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间"></el-table-column>
            <el-table-column label="机构" width="400">
                <template slot-scope="scope">
                    <div v-for="(item, index) in scope.row.institutions" class="mb10 bgcf2 p4 br5" :key="index">
                        <div>机构ID：{{ item.institutionId }}</div>
                        <div>机构名称：{{ item.institutionName }}</div>
                        <div>机构版本：{{ {
                            1: '体验版',
                            2: '平台版',
                            3: '年费版',
                            4: '五年版本'
                        }[item.version] }}</div>
                    </div>

                </template>
            </el-table-column>
            <!-- <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" @click="extendTime(scope.row.id)">
                        xxxxxxxxxxx
                    </el-button>
                </template>
            </el-table-column> -->
        </el-table>

        <el-pagination background layout="prev, pager, next,sizes" :total="total" :page-sizes="[10, 50, 100, 200]"
            :current-page.sync="params.pageIndex" :page-size.sync="params.pageSize" @size-change="getList"
            @current-change="getList" style="margin-top: 15px"></el-pagination>

    </div>
</template>

<script>
export default {
    created () {
        this.getList();
    },
    data () {
        return {
            list: [],
            total: 0,
            time: [],
            time2: [],
            params: {
                pageIndex: 1,
                pageSize: 10,
                nickName: "",
                phoneNumber: "",
                insertStartTime: "",
                insertEndTime: "",
                updateStartTime: "",
                updateEndTime: "",
            },
        };
    },
    methods: {
        getList () {
            if (this.time.length) {
                this.params.insertStartTime = this.time[0];
                this.params.insertEndTime = this.time[1];
            } else {
                this.params.insertStartTime = "";
                this.params.insertEndTime = "";
            }


            if (this.time.length) {
                this.params.updateStartTime = this.time2[0];
                this.params.updateEndTime = this.time2[1];
            } else {
                this.params.updateStartTime = "";
                this.params.updateEndTime = "";
            }

            this.$ajax
                .post("/admin/user/pageList", this.params)
                .then(res => {
                    this.list = res.list;
                    this.total = res.total;
                });
        },

    },
};
</script>

<style></style>
